<template>
  <el-container style="border: 1px solid #eee; height: 1080px">
    <el-header style="text-align: right; font-size: 12px">
      <el-row>
        <el-col :md="2">
          <ul class="el-menu--horizontal el-menu">
            <li class="el-menu-item">
              <a href="/mall" style="text-decoration-line: none">
                <img src="@/assets/img/icon/logo.png" class="el-avatar--circle el-avatar--medium" alt="img">
                mall
              </a>
            </li>
          </ul>
        </el-col>
        <el-col :md="20">
          <el-menu
            :default-active="this.$route.path"
            router
            mode="horizontal"
          >
            <el-submenu index="/mall/my">
              <template slot="title" class="el-icon-user">我的宝淘</template>
              <el-menu-item index="/mall/order">
                <template slot="title" class="el-icon-s-order">我的订单</template>
              </el-menu-item>
              <el-menu-item index="/mall/visit">
                <template slot="title" class="el-icon-shopping-cart-full">浏览记录</template>
              </el-menu-item>
              <el-menu-item index="/mall/myproduct">
                <template slot="title" class="el-icon-shopping-cart-full">商品管理</template>
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="/mall/cart">
              <template slot="title">
                <span>购物车</span>
                <el-badge class="item" :value="cartCount" :max="99" />
              </template>
            </el-menu-item>
            <el-menu-item index="/mall/fav">
              <template slot="title" class="el-icon-collection">收藏夹</template>
            </el-menu-item>
          </el-menu>
        </el-col>
        <el-col :md="2">
          <el-dropdown>
            <img
              :src="user.avatarUrl"
              class="el-avatar--circle el-avatar--medium"
              style="margin-right: 10px; margin-top: 15px"
              alt=""
            >
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                icon="el-icon-s-platform"
                class="size"
                @click.native="goToHome"
              >主站</el-dropdown-item>
              <el-dropdown-item
                icon="el-icon-error"
                class="size"
                @click.native="goToLogout"
              >退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
    </el-header>
    <el-container>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { userMixin } from 'assets/js/mixin'
import { getAuthedUser } from '@/utils/auth'
import { getCartItems } from '@/api/mall'

export default {
  name: 'Mall',
  mixins: [userMixin],
  data() {
    return {
      user: {
        avatarUrl: '//picx.zhimg.com/v2-df2ee990a53f8fa8d86226026630c354_xl.jpg'
      },
      cartCount: 0
    }
  },
  created() {
    document.title = '商城'
    const userInfo = getAuthedUser()
    if (userInfo !== null) {
      this.user = userInfo
    }

    getCartItems().then(resp => {
      if (resp.code === 0) {
        this.cartCount = resp.data.length
      }
    })
  }
}
</script>

<style>
</style>
